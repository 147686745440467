import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import "./WorkOrderKPIs.css"; // Add this file for styling

const WorkOrderKPIs = () => {
  const [kpis, setKpis] = useState({
    total_calls_per_day: 0,
    total_calls_per_week: 0,
    total_calls_per_month: 0,
    total_calls_year_to_date: 0,
    total_calls_dispatched_today: 0,
    total_calls_new_today: 0,
  });

  const [loading, setLoading] = useState(true); // Control page visibility
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [pressedCard, setPressedCard] = useState("");
  const [pressedCardName, setPressedCardName] = useState(""); // Track pressed card name
  const [leaderboardData, setLeaderboardData] = useState([]);

  const fetchKPIs = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${baseUrl}/api/work-order-kpi/`); // Replace with your API URL
      if (!response.ok) {
        throw new Error("Failed to fetch KPIs");
      }
      const data = await response.json();
      setKpis(data);
      setError(null); // Clear any previous errors
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false); // Stop loading after fetch completes
    }
  };

  useEffect(() => {
    // Fetch data initially
    fetchKPIs();

    // Set up interval to fetch KPIs every 30 seconds
    const interval = setInterval(() => {
      fetchKPIs();
    }, 30000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleCardClick = (leaderboardType, cardType, cardName) => {
    setPressedCard(cardType);
    setPressedCardName(cardName); // Store card name
    setLeaderboardData(kpis[leaderboardType]); // Load leaderboard data
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setPressedCard(""); // Reset pressed card
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        heading="WorkOrder KPIs"
        button1Label="Dashboard"
        button1Link="/"
        button2Label="Invoices Count"
        button2Link="/invoices-count"
      />

      <div className="px-5 mt-5">
        {error && <div className="text-danger text-center">Error: {error}</div>}

        <div className="row">
          <div
            className={`col-md-4 ${pressedCard === "today" ? "card-pressed" : ""}`}
            onClick={() =>
              handleCardClick("today_leaderboard", "today", "Calls Today")
            }
          >
            <div className="card text-center shadow-sm">
              <div className="card-body">
                <h5 className="card-title">Calls Today</h5>
                <p className="card-text display-6">{kpis.total_calls_per_day}</p>
              </div>
            </div>
          </div>

          <div
            className={`col-md-4 ${pressedCard === "week" ? "card-pressed" : ""}`}
            onClick={() =>
              handleCardClick("week_leaderboard", "week", "Calls This Week")
            }
          >
            <div className="card text-center shadow-sm">
              <div className="card-body">
                <h5 className="card-title">Calls This Week</h5>
                <p className="card-text display-6">{kpis.total_calls_per_week}</p>
              </div>
            </div>
          </div>

          <div
            className={`col-md-4 ${pressedCard === "month" ? "card-pressed" : ""}`}
            onClick={() =>
              handleCardClick("month_leaderboard", "month", "Calls This Month")
            }
          >
            <div className="card text-center shadow-sm">
              <div className="card-body">
                <h5 className="card-title">Calls This Month</h5>
                <p className="card-text display-6">{kpis.total_calls_per_month.toLocaleString('en-US')}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div
            className={`col-md-4 ${
              pressedCard === "year" ? "card-pressed" : ""
            }`}
            onClick={() =>
              handleCardClick("year_leaderboard", "year", "Calls Year to Date")
            }
          >
            <div className="card text-center shadow-sm">
              <div className="card-body">
                <h5 className="card-title">Calls Year to Date</h5>
                <p className="card-text display-6">
                  {kpis.total_calls_year_to_date.toLocaleString('en-US')}
                </p>

              </div>
            </div>
          </div>

          <div
            className={`col-md-4 ${
              pressedCard === "dispatched" ? "card-pressed" : ""
            }`}
            onClick={() =>
              handleCardClick(
                "today_dispatched_leaderboard",
                "dispatched",
                "Dispatched WorkOrders Today"
              )
            }
          >
            <div className="card text-center shadow-sm">
              <div className="card-body">
                <h5 className="card-title">Dispatched WorkOrders Today</h5>
                <p className="card-text display-6">
                  {kpis.total_calls_dispatched_today}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`col-md-4 ${pressedCard === "new" ? "card-pressed" : ""}`}
            onClick={() =>
              handleCardClick(
                "today_new_leaderboard",
                "new",
                "New WorkOrders Today"
              )
            }
          >
            <div className="card text-center shadow-sm">
              <div className="card-body">
                <h5 className="card-title">New WorkOrders Today</h5>
                <p className="card-text display-6">
                  {kpis.total_calls_new_today}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal fade show d-block" style={{ background: "#00000080" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Leaderboard ({pressedCardName})</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body mt-0">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Starting Dispatcher</th>
                      <th>Order Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaderboardData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.Starting_Dispatcher}</td>
                        <td>{item.order_count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkOrderKPIs;
