import { Routes, Route } from 'react-router-dom';

import './App.css';
import Dashboard from './components/Dashboard';
import WorkOrderKPIs from './components/WorkOrderKPIs';
import InvoicesCount from './components/InvoicesCount';

function App() {
  return (
    <>
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/kpi" element={<WorkOrderKPIs />} />
            <Route path="/invoices-count" element={<InvoicesCount />} />
         </Routes>
      
    </>
  );
}

export default App;
