import React from "react";
import { Link } from "react-router-dom";

const Navbar = ({ heading, button1Label, button1Link, button2Label, button2Link }) => {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary px-5">
      <div className="container-fluid justify-content-between">
        <div className="navbar-brand">
          <h2 className="custom-info">{heading}</h2>
        </div>
        <div>
          {/* Button 1 */}
          <Link to={button1Link}>
            <button className="btn btn-primary me-2">{button1Label}</button>
          </Link>

          {/* Button 2 */}
          <Link to={button2Link}>
            <button className="btn btn-primary">{button2Label}</button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
