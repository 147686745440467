import React, { useState, useEffect } from "react";
import Navbar from "./Navbar"; // Import Navbar component

const InvoicesCount = () => {
  const [kpis, setKpis] = useState({
    equipment_provider_data: [],
    work_type_data: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchInvoicesCount = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL; // Ensure this is configured
      const response = await fetch(`${baseUrl}/api/invoices-count/`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setKpis(data);
      setError(null); // Clear any previous errors
    } catch (err) {
      console.error("Error fetching invoices count:", err);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data initially
    fetchInvoicesCount();

    // Set up interval to refresh data every 30 seconds
    const interval = setInterval(() => {
      fetchInvoicesCount();
    }, 30000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <div>
      {/* Navbar */}
      <Navbar
        heading="WorkOrder Invoices Count"
        button1Label="Dashboard"
        button1Link="/"
        button2Label="KPIs"
        button2Link="/kpi"
      />

      {/* Main Content */}
      <div className="mx-5 mt-5">
        {error && <div className="text-danger text-center">Error: {error}</div>}
        
        {/* Table for Equipment Providers */}
        <div className="mt-5">
          <h3>Equipment Provider Data / Week</h3>
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Equipment Provider</th>
                  <th>Data Entry Complete</th>
                  <th>Generate Assignments</th>
                  <th>Invoice & Payable Created in QB</th>
                </tr>
              </thead>
              <tbody>
                {kpis.equipment_provider_data.length > 0 ? (
                  kpis.equipment_provider_data.map((provider, index) => (
                    <tr key={index}>
                      <td>{provider.Equipment_Provider}</td>
                      <td>{provider["Data Entry Complete"] || 0}</td>
                      <td>{provider["Generate Assignments"] || 0}</td>
                      <td>{provider["Invoice & Payable Created in QB"] || 0}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Table for Work Types */}
        <div className="mt-5">
          <h3>WorkType Data / Week</h3>
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>WorkType</th>
                  <th>Data Entry Complete</th>
                  <th>Generate Assignments</th>
                  <th>Invoice & Payable Created in QB</th>
                </tr>
              </thead>
              <tbody>
                {kpis.work_type_data.length > 0 ? (
                  kpis.work_type_data.map((workorder, index) => (
                    <tr key={index}>
                      <td>{workorder.Work_Type}</td>
                      <td>{workorder["Data Entry Complete"] || 0}</td>
                      <td>{workorder["Generate Assignments"] || 0}</td>
                      <td>{workorder["Invoice & Payable Created in QB"] || 0}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicesCount;
