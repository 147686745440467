import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./Dashboard.css";
import Navbar from "./Navbar";

const Dashboard = () => {
  const [data, setData] = useState({
    missing_timestamp: [],
    within_15_min: [],
    within_1_hour: [],
    within_2_hour: [],
    elapsed: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [activeTab, setActiveTab] = useState("all"); // Default active tab
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState("none");
  const allData = useMemo(() => {
    return [
      ...data.missing_timestamp.map((item) => ({
        ...item,
        category: "missing_timestamp",
      })),
      ...data.elapsed.map((item) => ({ ...item, category: "elapsed" })),
      ...data.within_15_min.map((item) => ({
        ...item,
        category: "within_15_min",
      })),
      ...data.within_1_hour.map((item) => ({
        ...item,
        category: "within_1_hour",
      })),
      ...data.within_2_hour.map((item) => ({
        ...item,
        category: "within_2_hour",
      })),
    ];
  }, [data]);

  const fetchData = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${baseUrl}/api/work-order-dashboard/`);
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `HTTP error! Status: ${response.status}, Response: ${errorText}`
        );
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error("Fetch error:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sortData = useCallback(
    (order) => {
      let sorted = [...allData]; // Start with a copy of the original data

      if (order === "none") {
        setSortedData(sorted); // If no sorting, just return the original data
        return;
      }

      // If sorting is applied (ascending or descending), perform the sorting
      const grouped = sorted.reduce((acc, item) => {
        acc[item.Starting_Dispatcher] =
          (acc[item.Starting_Dispatcher] || 0) + 1;
        return acc;
      }, {});

      // Sort by the frequency of the "Starting Dispatcher" count
      const sortedDispatchers = Object.entries(grouped).sort((a, b) => {
        const countA = a[1];
        const countB = b[1];
        return order === "asc" ? countA - countB : countB - countA;
      });

      // Reorganize the data by sorted dispatchers
      sorted = sortedDispatchers
        .map(([dispatcher]) => {
          return allData.filter(
            (item) => item.Starting_Dispatcher === dispatcher
          );
        })
        .flat();

      setSortedData(sorted); // Update the sorted data
    },
    [allData]
  );

  useEffect(() => {
    fetchData(); // Initial fetch on component mount

    const intervalId = setInterval(() => {
      fetchData();
    }, 30000); // Fetch every 30 seconds

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    sortData(sortOrder);
  }, [data, sortOrder, sortData]);

  

  const toggleSortOrder = () => {
    const newOrder = sortOrder === "asc" ? "desc" : sortOrder === "desc" ? "none" : "asc";
    setSortOrder(newOrder); // Update the sort order
    sortData(newOrder); // Apply sorting based on the new order
  };
  


  const handleRowClick = (item) => {
    setSelectedRow(item);
  };

  const truncateText = (text, maxLength = 15) => {
    if (!text) return "";
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Change active tab
  };

  // Get count for each category
  const getCategoryCount = (category) => {
    if (category === "all") {
      return allData.length; // Return the length of the combined data for "all" tab
    }
    return data[category]?.length || 0; // Return the length of the specific category
  };
  
  
  const formatTabName = (category) => {
    return category
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <h3>Loading...</h3>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const getRowClass = (category) => {
    switch (category) {
      case "missing_timestamp":
        return "missing-timestamp";
      case "within_15_min":
        return "within-15-min";
      case "within_1_hour":
        return "within-1-hour";
      case "within_2_hour":
        return "within-2-hour";
      case "elapsed":
        return "elapsed";
      default:
        return "";
    }
  };

  const sortButtons = (
    <div className="sort-buttons mb-3">
      <button onClick={toggleSortOrder} className="btn btn-secondary">
        {sortOrder === "none" 
          ? "Sort" 
          : `Sorted by Dispatcher(${sortOrder === "asc" ? "Least to Most" : "Most to Least"})`}
      </button>
    </div>
  );
  

  // Combine all categories for the "All" tab with their respective categories
  


  return (
    <>
      <div>
      <Navbar
        heading="WorkOrder Dashboard"
        button1Label="KPIs"
        button1Link="/kpi"
        button2Label="Invoices Count"
        button2Link="/invoices-count"
      />
      </div>
      {selectedRow && <div className="overlay show"></div>}

      <div className="px-5 mt-4">
        <div className="d-flex justify-content-between">
        
        {/* Bootstrap Nav Tabs */}
        <ul className="nav nav-tabs" id="dashboardTabs" role="tablist">
          {["all", "missing_timestamp", "elapsed", "within_15_min", "within_1_hour", "within_2_hour"].map((category) => (
            <li className="nav-item table-row" role="presentation" key={category}>
              <a
                className={`nav-link ${activeTab === category ? "active" : ""}`}
                id={`${category}-tab`}
                data-bs-toggle="tab"
                href={`#${category}`}
                role="tab"
                aria-controls={category}
                aria-selected={activeTab === category ? "true" : "false"}
                onClick={() => handleTabClick(category)}
              >
              <span className={`badge ${getRowClass(category)}`}> </span>  {category === "all" ? "All" : formatTabName(category)} ({getCategoryCount(category)})
              </a>
            </li>
          ))}
        </ul>
        {sortButtons}
        </div>
        {/* Tab Content */}
        <div className="tab-content mt-4" id="dashboardTabsContent">
          {["all","missing_timestamp", "elapsed", "within_15_min", "within_1_hour", "within_2_hour"].map((category) => (
            <div
              className={`tab-pane fade ${activeTab === category ? "show active" : ""}`}
              id={category}
              role="tabpanel"
              key={category}
            >
              {/* Table to display filtered data */}
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Work Order Number</th>
                    <th>Starting Dispatcher</th>
                    <th>Work Type</th>
                    <th>State</th>
                    <th>Customer</th>
                    <th>Vendor</th>
                    <th>Chassis</th>
                    <th>Trailer</th>
                    <th>Truck</th>
                    <th>Comment</th>
                    <th>Last Modified By</th>
                    <th>Last Modified</th>
                    <th>Vendor ETA Time</th>
                    <th>Vendor ETA Date</th>
                  </tr>
                </thead>
                <tbody>
                  {(category === "all" ? sortedData : data[category])?.length === 0 ? (
                    // Show message if no data is available for the category
                    <tr>
                      <td colSpan="12" className="text-center">No orders for category: {category === "all" ? "all categories" : formatTabName(category)}</td>
                    </tr>
                  ) : (
                    // Render table rows if there is data
                    (category === "all" ? sortedData : data[category])?.map((item, index) => (
                      <tr
                        key={`${category}-${index}`}
                        className="table-row"
                        onClick={() => handleRowClick(item)}
                      >
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.Work_Order_Number)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.Starting_Dispatcher)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.Work_Type)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.State)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.Customer)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.Vendor)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.Chassis)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.Trailer)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.Truck)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.Comment)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.MF_Last_Modified_By)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.LastModified)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.Vendor_Eta_Time)}</td>
                        <td className={category === "all" ? getRowClass(item.category) : getRowClass(category)}>{truncateText(item.Vendor_Eta_Date)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

            </div>
          ))}
        </div>
      </div>

      {/* Modal for displaying selected row data */}
      {selectedRow && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="detailModalLabel">
                  Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setSelectedRow(null)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Work Order Number</td>
                      <td>{selectedRow.Work_Order_Number}</td>
                    </tr>
                    <tr>
                      <td>Starting Dispatcher</td>
                      <td>{selectedRow.Starting_Dispatcher}</td>
                    </tr>
                    <tr>
                      <td>Work Type</td>
                      <td>{selectedRow.Work_Type}</td>
                    </tr>
                    <tr>
                      <td>State</td>
                      <td>{selectedRow.State}</td>
                    </tr>
                    <tr>
                      <td>Customer</td>
                      <td>{selectedRow.Customer}</td>
                    </tr>
                    <tr>
                      <td>Vendor</td>
                      <td>{selectedRow.Vendor}</td>
                    </tr>
                    <tr>
                      <td>Chassis</td>
                      <td>{selectedRow.Chassis}</td>
                    </tr>
                    <tr>
                      <td>Trailer</td>
                      <td>{selectedRow.Trailer}</td>
                    </tr>
                    <tr>
                      <td>Truck</td>
                      <td>{selectedRow.Truck}</td>
                    </tr>
                    <tr>
                      <td>Comment</td>
                      <td>{selectedRow.Comment}</td>
                    </tr>
                    <tr>
                      <td>Last Modified By</td>
                      <td>{selectedRow.MF_Last_Modified_By}</td>
                    </tr>
                    <tr>
                      <td>Last Modified</td>
                      <td>{selectedRow.LastModified}</td>
                    </tr>
                    <tr>
                      <td>Vendor ETA Time</td>
                      <td>{selectedRow.Vendor_Eta_Time}</td>
                    </tr>
                    <tr>
                      <td>Vendor ETA Date</td>
                      <td>{selectedRow.Vendor_Eta_Date}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setSelectedRow(null)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
